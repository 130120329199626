import { CarrerasExample } from "../../app/components/carreras/exampleCarreras";

export function findCarreer(value) {
  const carreer = CarrerasExample.find((item) => item.id == value);
  return carreer.name;
}

export function find_by_id(id, arr) {
  const r_arr = [];

  arr.forEach((item) => {
    if (item.carrera_id == id) {
      r_arr.push(item);
    }
  });

  return { r_arr };
}

export function find_description_by_id(id, arr, prop) {
  const _var = arr.find((item) => item.id === parseInt(id));
  return _var[prop];
}

export function find_in_arr_by_id(id, arr, prop, return_prop) {
  const _arr = [];
  let res = null;

  if (return_prop != null && return_prop != undefined) {
    arr.forEach((item) => {
      if (item[prop] == id) {
        console.log(item);
        res = item[return_prop].toString();
      }
    });
    return res;
  } else {
    arr.forEach((item) => {
      if (item[prop] == id) {
        _arr.push(item);
      }
    });

    return { _arr };
  }
}

export function find_string_in_arr_by_id(id, arr, prop, return_prop) {
  const _arr = [];
  let res = null;

  if (return_prop != null && return_prop != undefined) {
    if (arr.length > 0) {
      arr.forEach((item) => {
        if (item[prop] == id) {
          console.log(item);
          res = item[return_prop];
        }
      });
      return res;
    }

    return "";
  } else {
    arr.forEach((item) => {
      if (item[prop] == id) {
        _arr.push(item);
      }
    });

    return { _arr };
  }
}

export function transformText(inputText) {
  let textWithSpaces = inputText.replace(/_/g, " ");
  let words = textWithSpaces.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  let transformedText = words.join(" ");

  return transformedText;
}

export function nationalitySwitches(id){
  switch(id){
    case 1:
      return "Argentina";
    case 2:
      return "Uruguay";
    case 3:
      return "Brasil";
    case 4:
      return "Paraguay";
    case 5:
      return "Bolivia";
    case 6:
      return "Chile";
    case 7:
      return "Peru";
    case 8:
      return "Colombia";
    case 9:
      return "Venezuela";
    case 10:
      return "España";
  }
}